// 
// docs.scss
//

.hljs {
    display: block;
    overflow-x: auto;
    padding: 2em;
    color: $dark;
    max-height: 420px;
    margin: -10px 0 -30px;
    border: 1px solid rgba($gray-600, 0.2);
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
    color: $dark;
    font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
    color: $teal;
}

.hljs-string,
.hljs-doctag {
    color: $danger;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
    color: $danger;
    font-weight: bold;
}

.hljs-subst {
    font-weight: normal;
}


.hljs-tag,
.hljs-name,
.hljs-attribute {
    color: darken($blue, 15%);
    font-weight: normal;
}

.hljs-regexp,
.hljs-link {
    color: $teal;
}

.hljs-symbol,
.hljs-bullet {
    color: $pink;
}

.hljs-built_in,
.hljs-builtin-name {
    color: $info;
}

.hljs-meta {
    color: $gray-800;
    font-weight: bold;
}

.hljs-deletion {
    background: #fdd;
}

.hljs-addition {
    background: #dfd;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

.hljs-comment {
    color: $gray-400;
}