//
// Inter fonts
//

@font-face {
  font-family: "Macan Book";
  src: local("Macan Book"), local("Macan-Book"),
    url("../../../fonts/Macan/Macan-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//   font-family: "Macan-Stencil-Light";
//   src: url("path-to-your-font/Macan-Stencil-Light.ttf") format("truetype");
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "Macan Stencil Light";
  src: local("Macan Stencil Light"), local("Macan-Stencil-Light"),
    url("../../../fonts/Macan/Macan-Stencil-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Macan Light Mix";
  src: local("Macan Light Mix"), local("Macan-Light-Mix"),
    url("../../../fonts/Macan/Macan-Light-Mix.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Macan Light";
  src: local("Macan Light"), local("Macan-Light"),
    url("../../../fonts/Macan/Macan-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Macan Mono Medium";
  src: local("Macan Mono Medium"), local("Macan-Mono-Medium"),
    url("../../../fonts/Macan/Macan-Mono-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
